export * from './Input';

/**
 * Constants change the color of the label
 */
export enum LabelColors {
    GRAY = 'gray-dark',
    BLUE = 'blue',
}

/**
 * Constants change the color of the main input border
 */
export enum BorderColors {
    GRAY = 'border-gray-normal',
    GREEN = 'border-green',
}

/**
 * This interface describes wrapper properties
 *
 * @typeParam value: string - Prop input's value
 * @typeParam label: string - Optional Prop label value
 * @typeParam disabled: boolean - Optional prop it's used if input is a field only for showing and not changing its value
 * @typeParam requiredText: string - Optional prop it's used for changing text when field is being validated
 * @typeParam required: boolean - Optional prop it's used if input is required field
 * @typeParam children: JSX.Element - Optional Input children within wrapper
 * @typeParam labelColor: LabelColors - Optional change color to label
 * @typeParam borderColor: BorderColors - Optional change color to border input
 * @typeParam classWrapper?: string - Optional classes wrapper
 * @typeParam classesWrapperInput?: string - Optional classes wrapper input
 * @typeParam options?: IOptionSelect[] - Optional option input select
 * @typeParam optionSelected?: (option: IOptionSelect) => void - Optional on handle select
 * @typeParam classesInput?: string - Optional classes input
 * @typeParam containerClass?: string - Optional container classes input
 */
export interface IWrapperProps {
    value: string;
    label?: string;
    disabled?: boolean;
    requiredText?: string;
    required?: boolean;
    children?: JSX.Element;
    labelColor?: LabelColors;
    borderColor?: BorderColors;
    classWrapper?: string;
    classesWrapperInput?: string;
    options?: IOptionSelect[];
    optionSelected?: (option: IOptionSelect) => void;
    classesInput?: string;
    containerClass?: string;
}

/**
 * This interface describes input's properties extends from the wrapper
 *
 * @typeParam name: string - Optional name input
 * @typeParam type: string - Optional prop input's type according to variable
 * @typeParam placeholder: string - Optional prop placeholder
 * @typeParam onChange: (e: React.ChangeEvent<HTMLInputElement>) => void - Optional handle input
 * @typeParam colorText: string - Optional color text input
 */
export interface IPropsInput extends IWrapperProps {
    name?: string;
    type?: string;
    placeholder?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    colorText?: string;
}

/**
 * This interface describes the AdaptableTextArea props
 *
 * @typeParam value?: string - Optional Text area value
 * @typeParam placeholder: string - Optional prop with a text area placeholder
 * @typeParam labelText?: string - Optional prop with a text area label
 * @typeParam onChange?: (e: TextAreaEvent) => void - Optional prop with a function for the text area handle change
 * @typeParam name: string - Optional prop with the text area name
 * @typeParam classesLabel: string - Optional prop with a label classes fot customize the component
 * @typeParam classesInput: string - Optional prop with a input classes fot customize the component
 * @typeParam classesWrapper: string - Optional prop with a wrapper classes fot customize the component
 * @typeParam maxLength?: number - Optional prop with a select limit the text area
 * @typeParam required?: number - Optional prop that indicates if a field is required
 * @typeParam requiredText?: number - Optional prop with a custom required text
 * @typeParam disabled: boolean - Optional prop it's used if input is a field only for showing and not changing its value
 * @typeParam borderColor: BorderColors - Optional change color to border input
 */
export interface ITextAreaProps {
    value?: string;
    placeholder?: string;
    labelText?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (e: any) => void;
    name?: string;
    classesLabel?: string;
    classesInput?: string;
    classesWrapper?: string;
    maxLength?: number;
    required?: boolean;
    requiredText?: string;
    disabled?: boolean;
    borderColor?: BorderColors;
}

/*
 * This interface describes option select property
 *
 * @typeParam id: string - Optional id to get the identifier
 * @typeParam key: string - Key for reactjs
 * @typeParam value: string - Value og the option
 * @typeParam name: string - Optional Name to change the value of the input in the state
 */
export interface IOptionSelect {
    id?: string;
    key: string;
    value: string;
    name?: string;
}

/**
 * Max length for the text area and text input
 */
export const MAX_LENGTH_TEXT_AREA = 500;

/**
 * Default required text
 */
export const DEFAULT_REQUIRED_TEXT = '*Campo obligatorio';

/**
 * Text area rows
 */
export const TEXT_AREA_ROWS = 1;
