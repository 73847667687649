import { IDataCard } from '@components/card-main';
import { IIconProps } from '@components/Icon';
export { default } from './Home';

/**
 * Icon names for out clients section
 */
export const iconsOurClients: IIconProps[] = [
    {
        name: 'fidubogotaLogo',
    },
    {
        name: 'redladLogo',
    },
    {
        name: 'porvenirLogo',
    },
    {
        name: 'independencyLogo',
    },
    {
        name: 'onlineContributions',
    },
];

/**
 * This data crad services
 */
export const dataCardServices: IDataCard[] = [
    { icon: 'iconAdministrativeAffairs', title: 'Asuntos administrativos' },
    { icon: 'iconLegalIssues', title: 'Asuntos jurídicos' },
    { icon: 'iconDesingUx', title: 'Diseño UI/UX' },
    { icon: 'iconProductPhotography', title: 'Fotografía de producto' },
    { icon: 'iconoBrandIdentity', title: 'Identidad de marca' },
    { icon: 'iconStrategicPlanning', title: 'Planeación estratégica' },
    { icon: 'iconFinancialPlanning', title: 'Planeación financiera' },
    { icon: 'iconProductivityCompetitiveness', title: 'Productividad y competitividad' },
    { icon: 'iconPublicityMarketing', title: 'Publicidad y mercadeo' },
    { icon: 'iconoHumanResources', title: 'Recursos humanos y control interno' },
    { icon: 'iconoTechnology', title: 'Tecnología' },
];

/**
 * This data crad products
 */
export const dataCardTestimony: IDataCard[] = [
    {
        icon: 'wilsonMorera',
        title: 'Wilson Morera',
        subTitle: 'SALSAMENTARIA Y CARNICOS EMANUEL',
        text: `"diggi pymes brinda manejo del inventario y una buena administración para mi negocio."`,
        formatPng: true,
        indexPoint: 0,
    },
    {
        icon: 'briyithRuiz',
        title: 'Briyith Ruiz',
        subTitle: 'KLOCKAN',
        text: `"Es una herramienta muy intuitiva, además quiero hacer gestión de inventarios y 
        diggi pymes me ayuda a hacerlo de manera fácil"`,
        formatPng: true,
        indexPoint: 1,
    },
    {
        icon: 'darioSabogal',
        title: 'Dario Sabogal ',
        subTitle: 'RECCO',
        text: `"diggi pymes ha sido una herramienta amigable, útil y fácil de manejar ayudándonos, 
        facilitándonos el uso de la facturación electrónica"`,
        formatPng: true,
        indexPoint: 10,
    },
];

export const newDataCardTestimony: IDataCard[] = [
    {
        icon: 'alfredoMeza',
        title: 'Alfredo Meza',
        subTitle: 'INBES PHARMA SAS',
        text: `"Me permite facturar de una manera práctica y llevar de forma confiable los movimientos entre bodegas e informarme oportunamente de los inventarios"`,
        formatPng: true,
        indexPoint: 9,
    },
    {
        icon: 'aliciaNieto',
        title: 'Alicia Nieto',
        subTitle: 'FIELES COMO PERROS Y GATOS',
        text: `"Organización, facilidad de documental, buena Comunicación con mis clientes y generar mejoras para una buena y eficaz atención."`,
        formatPng: true,
        indexPoint: 8,
    },
    {
        icon: 'taniaMurcia',
        title: 'Tania Murcia',
        subTitle: 'DULCE PECADO',
        text: `"Debido a que actualmente el sistema que usamos en la empresa no cuenta con estos módulos, el poder tener ayuda personalizada es de gran ayuda para el crecimiento de nuestra empresa."`,
        formatPng: true,
        indexPoint: 7,
    },
    {
        icon: 'ximenaGomez',
        title: 'Ximena Gomez',
        subTitle: 'CHOMSKY ENGLISH',
        text: `"Módulos y secciones muy completos, organizados y prácticos."
        "Permite cumplir con lo indicado por la ley y formalizar la empresa."`,
        formatPng: true,
        indexPoint: 6,
    },
    {
        icon: 'carlosBedoya',
        title: 'Carlos Bedoya',
        subTitle: 'Taller de mecánica automotriz',
        text: `"Muy prácticos y útiles"`,
        formatPng: true,
        indexPoint: 5,
    },
    {
        icon: 'davidFigueredo',
        title: 'David Figueredo',
        subTitle: 'BEATVI',
        text: `"Como emprendedor, siento que estas secciones me pueden ayudar a impulsar y organizar mejor mi negocio."`,
        formatPng: true,
        indexPoint: 4,
    },
    {
        icon: 'mariaDuarte',
        title: 'Maria Duarte',
        subTitle: 'ALFA LEGACY',
        text: `"Estos módulos me permitirán llevar un control y organización de mi microempresa"`,
        formatPng: true,
        indexPoint: 3,
    },
    {
        icon: 'valentinaRestrepo',
        title: 'Valentina Restrepo',
        subTitle: 'AGLOSTEEL',
        text: `"diggi pymes brinda soluciones eficientes y eficaces para el proceso de facturación electrónica para Aglosteel"`,
        formatPng: true,
        indexPoint: 2,
    },
];

/**
 * This is constants
 */
export const constant = {
    sizeScreen: 768,
    countMaxgroup: 4,
    maxMembersModal: 6,
    minLeft: 420,
    minTop: 327,
    pathLandingDiggipymes: 'https://diggipymes.co',
};

export const textDescriptions = {
    descriptionDiggipymes: `El software de transformación digital para pymes colombianas que sí integra todas 
  las tareas operativas de tu negocio, de manera fácil y rápida.`,
};
