export { default } from "./HeaderNav";

/**
 * This data navbar
 */
export const dataNavbarList = [
  { text: "Nuestras soluciones", isIcon: false, nameElement: "our-solution", offSetLink:-30},
  { text: "Servicios", isIcon: false, nameElement: "services", offSetLink:-30 },
  { text: "Sobre nosotros", isIcon: false, nameElement: "about-us", offSetLink:-30 },
  {
    text: "Blog",
    isIcon: true,
    listLink: [
      { name: "CCXC le recomienda", href: "https://blog.ccxc.co/categories/ccxc-le-recomienda" },
      { name: "Videos", href: "https://blog.ccxc.co/categories/tips" },
      { name: "Archivo de noticias", href: "https://blog.ccxc.co/categories/archivo-de-noticias" },
    ],
    nameElement:''
  },
  { text: "Contáctenos", isIcon: false, nameElement: "contact-us", offSetLink:-80 },
];
