import React from 'react';
import Button from '@components/button';
import { CheckboxMain } from '@components/checkbox';
import { BorderColors, IOptionSelect, SelectInput, TextArea, TextInput } from '@components/input';
import { IForm, optionSelectForm } from '.';

const Form: React.FC<IForm> = ({
    handleChange = (): void => {},
    dataForm,
    handleChangeSelect = (): void => {},
    onClickSave = (): void => {},
    activeValidate = false,
    checkDataProcessing = false,
    handleDataProcessing = (): void => {},
}) => {
    return (
        <div className="class-container-form">
            <h3 className="class-container-form__title">Escríbanos sus dudas o inquietudes</h3>
            <p className="class-container-form__text--responsive">
                Si le interesa saber más sobre nuestros productos o necesita una asesoría, agende una cita con nosotros:
            </p>
            <div className="class-container-form__content--inputs">
                <TextInput
                    name="name_surname"
                    value={dataForm.name_surname}
                    placeholder="Nombre y apellido"
                    classWrapper="mt-2.25 lg:w-71.25 xlg:w-full w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    classesInput=""
                    colorText="gray-contact"
                    required={activeValidate && !dataForm.name_surname}
                />
                <TextInput
                    name="email"
                    value={dataForm.email}
                    placeholder="Correo "
                    classWrapper="mt-2.25 lg:w-71.25 w-full xlg:w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    colorText="gray-contact"
                    required={activeValidate && !dataForm.email}
                />
                <TextInput
                    name="phone"
                    value={dataForm.phone}
                    placeholder="Teléfono de contacto "
                    classWrapper="mt-2.25 lg:w-71.25 w-full xlg:w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    colorText="gray-contact"
                    required={activeValidate && !dataForm.phone}
                />
                <TextInput
                    name="company_name"
                    value={dataForm.company_name}
                    placeholder="Nombre de su empresa"
                    classWrapper="mt-2.25 lg:w-71.25 w-full xlg:w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    colorText="gray-contact"
                    required={activeValidate && !dataForm.company_name}
                />
                <SelectInput
                    name="affair"
                    value={dataForm.affair}
                    placeholder="Asunto"
                    classWrapper="w-full mt-2.25 form-text-inputs"
                    borderColor={BorderColors.GREEN}
                    options={optionSelectForm}
                    optionSelected={(e: IOptionSelect): void => handleChangeSelect(e)}
                    required={activeValidate && !dataForm.affair}
                    classesInput="overflow-y-scroll max-h-90 container-modal"
                />
                <TextArea
                    name="description"
                    value={dataForm.description}
                    placeholder="Escriba sus dudas o inquietudes…"
                    classesWrapper="mt-2.25 w-full text-gray-contact"
                    borderColor={BorderColors.GREEN}
                    classesInput="min-h-42.5"
                    onChange={(e): void => handleChange(e)}
                    required={activeValidate && !dataForm.description}
                />
                <CheckboxMain
                    checked={checkDataProcessing}
                    onChange={handleDataProcessing}
                    label="Si doy autorización expresa para el tratamiento de los datos aquí consignados"
                    classBox="mt-[1.09375rem] mb-[1.84375rem] mr-auto "
                    classLabel="text-base text-left font-poppins text-gray-dark"
                    validate={activeValidate && !checkDataProcessing}
                />
            </div>
            <Button
                text="Enviar"
                classes="bg-green w-40"
                onClick={(): void => {
                    onClickSave();
                }}
            />
        </div>
    );
};

export default Form;
