import Icon from '@components/Icon';
import { restProps } from '@utils/Props';
import React, { useState, useRef } from 'react';
import InputTextArea from 'react-autosize-textarea';
import {
    BorderColors,
    DEFAULT_REQUIRED_TEXT,
    IOptionSelect,
    IPropsInput,
    ITextAreaProps,
    IWrapperProps,
    LabelColors,
    MAX_LENGTH_TEXT_AREA,
    TEXT_AREA_ROWS,
} from '.';
import './Input.scss';

export const TextInput: React.FC<IPropsInput> = props => {
    const {
        name,
        value = '',
        onChange = (): void => {},
        placeholder = '',
        disabled = false,
        type = 'text',
        classesInput,
        colorText = 'gray-dark',
    } = props;

    return (
        <WrapperInput {...props}>
            <input
                type={type}
                autoComplete="off"
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    onChange(e);
                }}
                placeholder={placeholder}
                disabled={disabled}
                className={`input placeholder:text-xs text-${colorText} ${classesInput}`}
            />
        </WrapperInput>
    );
};

const WrapperInput: React.FC<IWrapperProps> = ({
    label = '',
    disabled = '',
    children,
    required = false,
    requiredText = '*Campo obligatorio',
    labelColor = LabelColors.BLUE,
    borderColor = BorderColors.GRAY,
    classWrapper = 'relative w-73',
    containerClass = '',
}) => {
    const wrapperClasses = ` input--container  ${
        borderColor === BorderColors.GREEN ? 'focus-within:border-purple-dark' : 'focus-within:border-green'
    }${disabled && 'input--disabled'} ${required ? 'input--required border' : `border ${borderColor}`}`;

    return (
        <div className={`flex flex-col relative ${classWrapper}`}>
            {label && <label className={`ml-1.5 text-tiny mb-1 font-poppins text-${labelColor} `}>{`${label}:`}</label>}
            <div className={`${containerClass} ${wrapperClasses}`}>{children}</div>
            {required && <label className="text-error">{requiredText ? requiredText : ''}</label>}
        </div>
    );
};

export const TextArea: React.FC<ITextAreaProps> = React.memo(
    ({
        name = '',
        value = '',
        labelText = '',
        placeholder = '',
        onChange = (): void => {},
        classesLabel = '',
        classesInput = '',
        classesWrapper = '',
        maxLength = MAX_LENGTH_TEXT_AREA,
        disabled = false,
        required = false,
        requiredText = DEFAULT_REQUIRED_TEXT,
        borderColor = BorderColors.GRAY,
    }) => (
        <div className={classesWrapper}>
            {labelText && (
                <label className={`block ml-1.5 mb-1 font-allerbold text-blue text-tiny ${classesLabel}`}>{labelText}</label>
            )}
            <div
                className={`flex items-center gap-2 rounded-md  ${
                    disabled
                        ? 'bg-gray-light'
                        : `${required ? 'input--required border' : `border ${borderColor}`} ${
                              borderColor === BorderColors.GREEN ? 'focus-within:border-purple-dark' : 'focus-within:border-green'
                          } `
                }`}
            >
                <InputTextArea
                    className={`adaptable-text-area text-gray ${value ? '-dark' : ''} ${classesInput} ${
                        required && !value ? 'input--required' : ''
                    }`}
                    rows={TEXT_AREA_ROWS}
                    {...restProps({ name, value, placeholder, onChange, maxLength })}
                    disabled={disabled}
                />
            </div>
            {required && <label className="text-error">{requiredText}</label>}
        </div>
    )
);

export const SelectInput: React.FC<IPropsInput> = props => {
    const { options = [], disabled, value, placeholder = '', optionSelected = (): void => {}, classesInput = '' } = props;

    const [showOptions, setShowOptions] = useState(false);
    const [rotate, setRotate] = useState(false);
    const [optionValue, setOptionValue] = useState({
        key: '',
        value: '',
        name: '',
    });
    const selectValue = (): string | number => {
        if (value) {
            return value;
        }
        if (optionValue?.value) {
            return optionValue.value;
        }
        return placeholder;
    };
    const selectParentRef = useRef(null);

    const onClickValue = (option: IOptionSelect): void => {
        setShowOptions(false);
        setRotate(false);
        setOptionValue({
            key: option.key,
            value: option.value,
            name: option.name ? option.name : '',
        });
        optionSelected(option);
    };

    return (
        <WrapperInput
            {...{
                ...props,
                classesWrapperInput: `${props.classesWrapperInput} relative ${showOptions ? 'border-secondary' : ''} ${
                    optionValue?.value ? 'border-gray-dark text-gray-dark;' : ''
                }`,
            }}
        >
            <div
                className={`absolute top-0 flex flex-col w-full ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
                ref={selectParentRef}
            >
                <div
                    onClick={
                        disabled
                            ? (): void => {}
                            : (): void => {
                                  setShowOptions(!showOptions);
                                  setRotate(!rotate);
                              }
                    }
                    className="flex items-center justify-between h-8"
                    id="select-custom"
                >
                    <p
                        className={`px-2 overflow-hidden whitespace-nowrap float-left overflow-ellipsis ${
                            value || optionValue?.value ? 'text-gray-smooth' : 'text-gray-smooth '
                        }`}
                    >
                        {selectValue()}
                    </p>
                    <Icon
                        name="arrowDown"
                        className={`transition duration-200 transform float-right mr-2 ${rotate ? 'rotate-180' : 'rotate-0'}`}
                    />
                </div>
                <ul
                    className={`${
                        showOptions ? 'block' : 'hidden'
                    } ${classesInput} bg-gray-light top-1 shadow-templateDesign z-10 rounded-md relative`}
                >
                    {options.map((option: IOptionSelect, index) => {
                        return (
                            <li
                                key={option.key}
                                className={` text-sm text-center rounded-md hover:bg-primary hover:text-white ${
                                    !(options.length - 1 === index) ? 'pt-2.5' : 'py-2.5 border-b'
                                }`}
                                onClick={(): void => {
                                    onClickValue(option);
                                }}
                            >
                                {option.value}
                                {!(options.length - 1 === index) && (
                                    <div className="w-11/12 pb-2.5 ml-5 border-b border-gray-normal" />
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </WrapperInput>
    );
};
