import React from 'react';

export const HOME = {
    DESCRIPTION_HEAD_COMPLEMENT: (
        <>
            <p className=" max-w-228 text-center text-xxl font-poppins text-gray font-medium mb-4.5 xs:text-sm">
                <span className=" text-green">
                    Somos una FinTech de impacto social, dedicada a impulsar la transformación digital de las Pymes colombianas
                    &nbsp;
                </span>
                a través del
                <span className="text-blue">&nbsp; desarrollo de plataformas especializadas de software</span>
                &nbsp; y de la aplicación de consultorías multidisciplinarias personalizadas, herramientas de valor agregado para
                este ecosistema,
                <span className="text-blue">&nbsp; diseñadas bajo pilares de Eficiencia, Productividad y Competitividad.</span>
            </p>
        </>
    ),
    DESCRIPTION_OUR_SOLUTIONS: (
        <>
            <p className="max-w-141.75 text-center text-xl font-poppins text-gray font-medium xs:text-sm mt-5">
                Somos &nbsp;
                <span className=" text-green">proveedores tecnológicos </span>
                de facturación electrónica, conozca nuestros softwares
            </p>
        </>
    ),
    DESCRIPTION_SOFTWARE: (
        <>
            <p className="font-medium font-poppins text-gray">
                Brindamos desarrollo de software personalizado, generando soluciones
                <span className="text-green">&nbsp; enfocadas en sus necesidades.</span>
            </p>
        </>
    ),
    DESCRIPTION_CONSULTANCY: (
        <>
            <p className="font-medium lg:text-left font-poppins text-gray">
                Contamos con un equipo de profesionales especializados en cada área para brindar
                <span className="text-green">&nbsp; asesoramiento estratégico personalizado</span>
                &nbsp; de calidad.
            </p>
        </>
    ),
    ABOUT_US: (
        <>
            <p className="text-base font-medium text-center font-poppins text-gray mb-4.75 xs:mb-0 xs:invisible xs:h-0 xs:w-0">
                Conozca más sobre CCxC
            </p>
            <p className="text-lg font-medium text-center font-poppins text-gray xs:text-sm">
                Somos una fintech de impacto social ubicado en Bogotá, Colombia con casa matriz en Nueva York, EE.UU.
                <span className="text-green">
                    &nbsp; Contamos con más de 6 años de experiencia y un grupo de más de 40 profesionales jóvenes de todas las
                    áreas de conocimiento.
                </span>
                &nbsp; Nuestra misión nos ha llevado a trabajar con el apoyo de entidades como el laboratorio de innovación del
                grupo BID (BID LAB), posicionándonos como una
                <span className="text-green">
                    &nbsp; empresa innovadora en la transformación digital de las pequeñas empresas.
                </span>
            </p>
        </>
    ),
    CONTACT_US: (
        <>
            <p className="text-lg font-medium text-left font-poppins text-gray xs:hidden h-22">
                Si le interesa saber más sobre nuestros productos o necesitas una asesoría, agende una cita con nosotros.
            </p>
            <p className="text-lg mt-4.5 xs:mt-0 font-medium text-left font-poppins text-gray-contact xs:text-sm w-full xs:text-center leading-5.5">
                Teléfono: 3167428648
                <br />
                Correo: ventas@ccxc.us
                <br />
                Dirección: Calle 26 #68C-61
                <br />
                Edificio Torre Central, oficina 301
                <br />
                Bogotá, Colombia
            </p>
        </>
    ),
};
