import axios from 'axios';
import { HttpMethod, ServiceType } from '../models/Request';

const baseUrl = process.env.REACT_APP_BASE_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const aboutUs = async (data: any): Promise<any> => {
    try {
        const response = await axios({
            url: `${baseUrl}/api/auth/notification`,
            headers: { 'Content-Type': 'application/json' },
            method: HttpMethod.POST,
            data: {
                resource: '/notifications/contact-lading',
                method: HttpMethod.POST,
                service: ServiceType.NOTIFICATION,
                data: data,
            },
        });
        return response.status;
    } catch (error) {
        return error;
    }
};
